<template>

  <div>
    <b-card title="Module Settings">
      <b-button-toolbar justify>
        <div />
        <div>
          <b-button
            v-if="activeUserInfo.userRole === 'admin' || activeUserInfo.userRole === 'company admin'"
            v-b-tooltip.hover="'Update'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Update()"
          >
            <font-awesome-icon
              :icon="['far', 'save']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <div class="divider my-2">
        <div class="divider-text">
          Entry Summary
        </div>
      </div>
      <b-row>
        <b-col md="6">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="EntrySummary"
              :value="true"
            >
              Production
            </b-form-radio>
            <b-form-radio
              v-model="EntrySummary"

              :value="false"
            >
              Certification
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <div class="divider my-2">
        <div class="divider-text">
          ISF
        </div>
      </div>
      <b-row>
        <b-col md="6">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="ISF"
              :value="true"
            >
              Production
            </b-form-radio>
            <b-form-radio
              v-model="ISF"

              :value="false"
            >
              Certification
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <div class="divider my-2">
        <div class="divider-text">
          Query
        </div>
      </div>
      <b-row>
        <b-col md="6">
          <label><i><b>MID:</b></i></label>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="MID"
              :value="true"
            >
              Production
            </b-form-radio>
            <b-form-radio
              v-model="MID"

              :value="false"
            >
              Certification
            </b-form-radio>
          </div>
        </b-col>
        <b-col md="6">
          <label><i><b>AD/CVD:</b></i></label>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="ADCVD"
              :value="true"
            >
              Production
            </b-form-radio>
            <b-form-radio
              v-model="ADCVD"

              :value="false"
            >
              Certification
            </b-form-radio>
          </div>
        </b-col>
        <b-col md="6">
          <label><i><b>Cargo Status:</b></i></label>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="CargoStatus"
              :value="true"
            >
              Production
            </b-form-radio>
            <b-form-radio
              v-model="CargoStatus"

              :value="false"
            >
              Certification
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <div class="divider my-2">
        <div class="divider-text">
          Extract Reference
        </div>
      </div>
      <b-row>
        <b-col md="6">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="ExtractReference"
              :value="true"
            >
              Production
            </b-form-radio>
            <b-form-radio
              v-model="ExtractReference"

              :value="false"
            >
              Certification
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      EntrySummary: false,
      ISF: false,
      ADCVD: false,
      MID: false,
      CargoStatus: false,
      ExtractReference: false,
    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.GetModuleSetting()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    GetModuleSetting() {
      return axios.get('/abi/settings/module/all')
        .then(resp => {
          const response = resp.data
          response.forEach(e => {
            switch (e.moduleName) {
              default:
                break
              case 'EntrySummary':
                this.EntrySummary = e.status
                break
              case 'ISF':
                this.ISF = e.status
                break
              case 'AD/CVD':
                this.ADCVD = e.status
                break
              case 'MID':
                this.MID = e.status
                break
              case 'CargoStatus':
                this.CargoStatus = e.status
                break
              case 'ExtractReference':
                this.ExtractReference = e.status
                break
            }
          })
        })
    },
    Update() {
      this.LoadingStart()
      const ModuleStatusModel = [
        {
          ModuleName: 'EntrySummary',
          Status: this.EntrySummary,
        },
        {
          ModuleName: 'ISF',
          Status: this.ISF,
        },
        {
          ModuleName: 'AD/CVD',
          Status: this.ADCVD,
        },
        {
          ModuleName: 'MID',
          Status: this.MID,
        },
        {
          ModuleName: 'CargoStatus',
          Status: this.CargoStatus,
        },
        {
          ModuleName: 'ExtractReference',
          Status: this.ExtractReference,
        },
      ]
      axios.put('/abi/settings/module/update',
        ModuleStatusModel).then(() => {
        this.LoadingEnd()
        this.DisplaySuccess('Module Setting data has been saved.')
      })
    },
  },
}
</script>
